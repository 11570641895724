<template>
  <div class="pages">
    <div class="back-up">
      <a href="javascript:;" @click="$router.back()">返回上一级</a>
    </div>
    <div class="input-info" v-loading="loading">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>我的报备</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item">
          <span>姓名：</span>
          <span>{{ pageData?.client_name }}</span>
        </div>
        <div class="item">
          <span>性别：</span>
          <span>{{ pageData?.client_sex === 1 ? '男' : '女' }}</span>
        </div>
        <div class="item">
          <span>联系电话：</span>
          <span>{{ pageData?.client_phone }}</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item">
          <span>性质：</span>
          <span>{{ pageData?.client_type ? '购买' : '租赁' }}</span>
        </div>
        <div class="item">
          <span>到访时间：</span>
          <span>{{ pageData?.come_time }}</span>
        </div>
        <div class="item">
          <span>案场接待：</span>
          <span>{{ pageData?.receptionist?.name }}</span>
        </div>
      </div>
      <div class="info-btn">
        <router-link :to="`/CommissionUpload?id=${$route.query.id}`" class="btn">
          <span>上传独家协议</span>
        </router-link>
        <el-popover
            title="带访预约修改"
            :width="270"
            trigger="click"
            placement="bottom"
            content="this is content, this is content, this is content"
        >
          <template #reference>
            <div class="btn">
              <span>带访预约修改</span>
            </div>
          </template>

          <template #default>
            <el-button type="primary" @click="handleChangeDaoTimeClick">修改到访时间</el-button>
            <el-button type="warning" @click="handleCancelDaoClick">取消预约到访</el-button>
          </template>
        </el-popover>
        <div class="btn-image">
          <el-image v-if="pageData?.can_with_see === 1" src="/static/images/can.png" fit="cover"/>
          <el-image v-if="pageData?.can_with_see === 0" src="/static/images/not_can.png" fit="cover"/>
        </div>
      </div>
    </div>
    <div class="input-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>需求信息</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item">
          <span>意向类型：</span>
          <span>{{ (pageData?.intention === null) ? '-' : (pageData?.intention?.name) }}</span>
        </div>
        <div class="item">
          <span>需求面积：</span>
          <span>{{ pageData?.demand_area_min }}~{{ pageData?.demand_area_max }}㎡</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item">
          <span>客户备注：</span>
          <span>{{ pageData?.remark }}</span>
        </div>
      </div>
    </div>
    <div class="input-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>经纪人信息</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item">
          <span>姓名：</span>
          <span>{{ pageData?.broker_name }}</span>
        </div>
        <div class="item">
          <span>手机号：</span>
          <span>{{ pageData?.broker_phone }}</span>
        </div>
        <div class="item">
          <span>公司：</span>
          <span>{{ pageData?.broker_company?.name }}</span>
        </div>
      </div>
    </div>
    <div class="input-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>目前进度</span>
        </div>
      </div>
      <div class="timeline-info">
        <template v-for="(item,index) in pageData?.reportLog" :key="item.id">
          <div class="item">
            <img v-if="item.status === 1" src="/static/images/bbpd_icon1.png" alt="">
            <img v-if="item.status === 2" :src="`/static/images/bbyx_icon1${item.active===false?'-off':''}.png`" alt="">
            <img v-if="item.status === 3" :src="`/static/images/bbwx_icon1${item.active===false?'-off':''}.png`" alt="">
            <img v-if="item.status === 4" :src="`/static/images/bbdf_icon1${item.active===false?'-off':''}.png`" alt="">
            <img v-if="item.status === 5" :src="`/static/images/bbrg_icon1${item.active===false?'-off':''}.png`" alt="">
            <img v-if="item.status === 6" :src="`/static/images/bbqy_icon1${item.active===false?'-off':''}.png`" alt="">
            <img v-if="item.status === 7" :src="`/static/images/bbjy_icon1${item.active===false?'-off':''}.png`" alt="">
            <span class="tit">{{ item.status_text || item.type_text }}</span>
            <span class="date" v-if="item.active!==false">{{ item.create_time }}</span>
            <span class="mask" v-if="item.active!==false">备注：{{ item.remark }}</span>
          </div>
          <div class="line" v-if="index !== 6"></div>
        </template>
      </div>
    </div>
    <div class="report-btn">
      <div class="btn" :class="{disable:!pageData?.can_commission_application}"
           @click="handleCommissionApplicationClick">
        <span>结佣申请</span>
      </div>
      <div class="btn" @click="handleRecommendClick">
        <span>推荐至其他楼盘</span>
      </div>
    </div>
    <el-dialog
        v-model="changeDaoTimeDialog.show"
        :title="changeDaoTimeDialog.title"
        width="30%"
    >
      <el-form :model="changeDaoTimeDialog.form" label-width="120px">
        <el-form-item label="到访日期">
          <el-select class="date" v-model="changeDaoTimeDialog.form.come_time_date" :teleported="false"
                     placeholder="到访日期">
            <el-option
                v-for="item in come_time_date_list"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="到访时间">
          <el-select v-model="changeDaoTimeDialog.form.come_time_time" :teleported="false" placeholder="到访时间">
            <el-option
                v-for="item in come_time_time_list"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleChangeDaoTimeDialogFooterCancelClick">取消</el-button>
        <el-button type="primary" @click="handleChangeDaoTimeDialogFooterConfirmClick"
                   :disabled="changeDaoTimeDialog.submitting">提交</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="commissionApplicationDialog.show"
        :title="commissionApplicationDialog.title"
        width="40%"
    >
      <div class="commissionApplication">
        <div class="item">
          <el-image src="/static/images/jy_upload.png" fit="contain"></el-image>
          <span>点击上传</span>
          <input type="file" ref="commissionApplicationInput" @change="handleCommissionApplicationFileChange">
        </div>
        <div class="item" @click="handleDownloadTemplateClick">
          <el-image src="/static/images/jy_download.png" fit="contain"></el-image>
          <span>下载模板</span>
        </div>
      </div>
      <div class="commissionApplicationFiles">
        <el-image class="item" v-for="item in commissionApplicationDialog.files" :key="item" :src="item"
                  fit="contain"></el-image>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCommissionApplicationDialogFooterCancelClick">取消</el-button>
        <el-button type="primary" @click="handleCommissionApplicationDialogFooterConfirmClick"
                   :disabled="commissionApplicationDialog.submitting">提交</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'MyReportInfo',
  data() {
    return {
      loading: false,
      changeDaoTimeDialog: {
        show: false,
        submitting: false,
        title: '修改到访时间',
        form: {
          come_time_date: '',
          come_time_time: '',
        }
      },
      come_time_date_list: [],
      come_time_time_list: [],
      pageData: {},
      commissionApplicationDialog: {
        show: false,
        submitting: false,
        title: '附属结佣表模板下载',
        files: [],
      }
    }
  },
  created() {
    this.buildComeTimeDateList()
    this.buildComeTimeTimeList()
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        let res = await this.http({
          url: '/api/my/reportDetail',
          method: 'GET',
          params: {
            report_id: this.$route.query.id
          }
        })
        this.pageData = res.data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

    },
    handleRecommendClick() {
      this.$router.push({
        path: '/report',
      })
    },
    handleChangeDaoTimeClick() {
      this.changeDaoTimeDialog.show = true
    },
    async handleCancelDaoClick() {
      await this.http({
        url: '/api/my/reportCancel',
        method: 'POST',
        data: {
          report_id: this.$route.query.id
        }
      })
      this.$message({
        type: 'success',
        message: '操作成功'
      })
      this.fetchData()
    },
    buildComeTimeDateList() {
      let currentDate = (new Date())

      for (let i = 0; i !== 7; i++) {
        this.come_time_date_list.push(`${(currentDate.getMonth() + 1).toString().padStart(2, '0')}月${currentDate.getDate().toString().padStart(2, '0')}日`)
        currentDate.setDate(currentDate.getDate() + 1)
      }
    },
    buildComeTimeTimeList() {
      for (let h = 8; h !== 23; h++) {
        this.come_time_time_list.push(`${h.toString().padStart(2, '0')}:00`)
        this.come_time_time_list.push(`${h.toString().padStart(2, '0')}:30`)
      }
    },
    handleChangeDaoTimeDialogFooterCancelClick() {
      this.changeDaoTimeDialog.show = false
    },
    async handleChangeDaoTimeDialogFooterConfirmClick() {
      // 请求接口
      try {
        this.changeDaoTimeDialog.submitting = true
        await this.http({
          url: '/api/my/reportComeTime',
          method: 'POST',
          data: {
            report_id: this.$route.query.id,
            come_time: `${this.changeDaoTimeDialog.form.come_time_date} ${this.changeDaoTimeDialog.form.come_time_time}`
          }
        })

        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.changeDaoTimeDialog.show = false
        this.fetchData()
      } catch (e) {
        console.log(e)
      } finally {
        this.changeDaoTimeDialog.submitting = false
      }
    },
    async handleCommissionApplicationClick() {
      if (!this.pageData?.can_commission_application) {
        return
      }

      for (let index in this.pageData?.reportLog || []) {
        if (this.pageData?.reportLog[index].status === 6 && this.pageData?.reportLog[index].active === false) {
          this.$message({
            type: 'warning',
            message: '没有签约不可结佣'
          })
          return
        }
      }

      this.commissionApplicationDialog.show = true
    },
    async handleCommissionApplicationFileChange(e) {
      if (e.target.files.length === 0) {
        return
      }
      if (this.commissionApplicationDialog.submitting) {
        return
      }

      try {
        this.commissionApplicationDialog.submitting = true
        var formData = new FormData

        formData.append('file', e.target.files[0])
        let res = await this.http({
          url: '/api/common/uploadFile',
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
          },
          data: formData
        })

        this.commissionApplicationDialog.files.push(res.data.path)
      } catch (e) {
        console.log(e)
      } finally {
        this.$refs['commissionApplicationInput'].value = ''
        this.commissionApplicationDialog.submitting = false
      }
    },
    async handleCommissionApplicationDialogFooterCancelClick() {
      this.commissionApplicationDialog.show = false
    },
    async handleCommissionApplicationDialogFooterConfirmClick() {
      if (this.commissionApplicationDialog.files.length === 0) {
        this.$message({
          type: 'warning',
          message: '请上传结佣申请表'
        })
      }
      try {
        this.commissionApplicationDialog.submitting = true
        await this.http({
          url: '/api/my/commissionApplicationSave',
          method: 'POST',
          data: {
            report_id: this.$route.query.id,
            images: this.commissionApplicationDialog.files,
          }
        })
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.commissionApplicationDialog.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.commissionApplicationDialog.submitting = false
      }
    },
    async handleDownloadTemplateClick() {
      let res = await this.http({
        url: '/api/index/report_table',
        method: 'GET',
        params: {
          report_id: this.$route.query.id,
        }
      })

      if (res.data.link === null) {
        this.$message({
          type: 'warning',
          message: '当前没有结佣申请表可供下载',
        })
        return
      }
      window.open(res.data.link, '_blank')

      console.log(res)
    }
  }
}
</script>

<style scoped lang="scss">
@import "MyReportInfo.scss";
</style>
